import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LandingPage from './pages/LandingPage';
//import InfosPage from './pages/InfosPage';
import CommunityPage from './pages/community/CommunityPage';

import MyPage from './pages/MyPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';  // 비밀번호 찾기 페이지
import SignupPage from './pages/SignupPage';
import EmailSignupPage from './pages/EmailSignupPage';
import CompleteProfilePage from './pages/CompleteProfilePage';

// Resume pages
import ResumeGeneratorPage1 from './pages/tools/resume/ResumeGeneratorPage1';  // 자소서 생성기 step1 페이지
import ResumeGeneratorPage2 from './pages/tools/resume/ResumeGeneratorPage2';  // 자소서 생성기 step2 페이지
import ResumeViewPage from './pages/tools/resume/ResumeViewPage';  // 자소서 저장 후 view 페이지

// Photo pages
import PhotoGeneratorPage from './pages/tools/photos/PhotoGeneratorPage';

// Questions pages
import QuestionSetInfoPage1 from './pages/tools/questions/QuestionSetInfoPage1';  // 면접 예상 질문 생성 step1 페이지
import QuestionSetInfoPage2 from './pages/tools/questions/QuestionSetInfoPage2';  // 면접 예상 질문 생성 step1 페이지
import QuestionViewPage from './pages/tools/questions/QuestionViewPage';  // 면접 예상 질문 view 페이지

// TechTree Pages
import TechTreeGeneratorPage from './pages/tools/techtree/TechTreeGeneratorPage';
import TechTreeDetail from './pages/tools/techtree/TechTreeDetail';  // 새로 추가

import JobMatchingPage from './pages/tools/JobMatchingPage';
import AIInterviewerPage from './pages/tools/AIInterviewerPage';
import ResumeEvaluationPage from './pages/tools/resumeevaluation/ResumeEvaluationPage'; // 새로 추가

// recruit information pages
import RecruitInfoPage from './pages/recruit/RecruitInfoPage';

import GoogleCallback from './pages/GoogleCallback';
import MainLayout from './layouts/MainLayout';

// 결제 관련
import Payment from './pages/payment/Payment';
import PaymentPage from './pages/payment/PaymentPage';
import GetBillingKey from './pages/payment/GetBillingKey';  // 빌링 키 발급

import './App.css';

// 접근 권한 관련
import ProtectedRoute from './components/ProtectedRoute';   // 회원 접근 가능
import AdminProtectedRoute from './components/AdminProtectedRoute';   // 관리자 접근 가능

// 자소서 평가 저장 페이지
import SavedResumeEvaluation from './components/saved/SavedResumeEvaluations';
// 자소서 평가 상세 페이지
import ResumeEvaluationDetail from './pages/tools/resumeevaluation/ResumeEvaluationDetail';
// 커뮤니티 게시글 상세 페이지
import PostDetail from './pages/community/PostDetail';
import WritePost from './pages/community/WritePost';
import EditPost from './pages/community/EditPost'; // 새로 추가

// Admin 레이아웃
import AdminLayout from './layouts/AdminLayout';

// Admin 페이지
import AdminPage from './pages/admin/AdminPage';

//이력서 생성기 페이지
import ResumeBuilderPage from './pages/tools/resumebuilder/ResumeBuilderPage';
import ResumePreviewPage from './pages/tools/resumebuilder/ResumePreviewPage';
//이력서 생성기 저장 페이지
import SavedBuilderResumes from './components/saved/SavedBuilderResumes';

// ResumeBuilderView 컴포넌트를 위한 새로운 라우트
import ResumeBuilderView from './pages/tools/resumebuilder/ResumeBuilderView';

// Admin 페이지
import AdminDashboard from './pages/admin/AdminDashboard';
import UsersManagementPage from './pages/admin/UsersManagementPage';
import CommunityManagementPage from './pages/admin/CommunityManagementPage';
import ReportsManagementPage from './pages/admin/ReportsManagementPage';

// 통계 페이지 import
import VisitorStatsPage from './pages/admin/statistics/VisitorStatsPage';
import NewMemberStatsPage from './pages/admin/statistics/NewMemberStatsPage';
import RevenueStatsPage from './pages/admin/statistics/RevenueStatsPage';

// 고객소통 페이지 import
import CustomerEmailPage from './pages/admin/communication/CustomerEmailPage';
import NoticePage from './pages/admin/communication/NoticePage';
import NotificationPage from './pages/admin/communication/NotificationPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/email-signup" element={<EmailSignupPage />} />
          <Route path="/complete-profile" element={<CompleteProfilePage />} /> {/* CompleteProfilePage 추가 */}
          <Route element={<MainLayout />}>
            <Route path="/recruit/info" element={<RecruitInfoPage />} />
            <Route path="/main" element={< MainPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/payment/method" element={<PaymentPage />} />
            <Route path="/payment/getbilling" element={<GetBillingKey />} />  {/* 빌링 키 발급 페이지(카드 등록) */}

            <Route path="/mypage" element={<ProtectedRoute element={MyPage} />} />
            <Route path="/tools/resume-generator/setinfo" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/setinfo/:coverLetterId" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/generate/:coverLetterId" element={<ProtectedRoute element={ResumeGeneratorPage2} />} />
            <Route path="/coverletter/view/:coverLetterId" element={<ProtectedRoute element={ResumeViewPage} />} />
            <Route path="/question/view/:questionId" element={<ProtectedRoute element={QuestionViewPage} />} />
            <Route path="/mypage/*" element={<ProtectedRoute element={MyPage} />} />
            <Route path="/tools/photo-generator" element={< PhotoGeneratorPage />} />
            <Route path="/tools/questions-setInfo" element={< QuestionSetInfoPage1 />} />
            <Route path="/tools/questions-setCategory" element={< ProtectedRoute element={QuestionSetInfoPage2} />} />
            <Route path="/tools/tech-tree-generator" element={< ProtectedRoute element={TechTreeGeneratorPage} />} />
            <Route path="/tools/tech-tree/:techTreeId" element={< ProtectedRoute element={TechTreeDetail} />} />
            <Route path="/tools/job-matching" element={< JobMatchingPage />} />
            <Route path="/tools/ai-interviewer" element={< AIInterviewerPage />} />
            <Route path="/tools/resume-evaluation" element={<ProtectedRoute element={ResumeEvaluationPage} />} /> 
            <Route path="/saved-evaluations" component={SavedResumeEvaluation} />
            <Route path="/resume-evaluation/:id" element={<ResumeEvaluationDetail />} />
            
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/community/post/:postId" element={<PostDetail />} />
            
            <Route path="/community/writePost" element={<ProtectedRoute element={WritePost} />} />
            <Route path="/community/edit/:postId" element={<ProtectedRoute element={EditPost} />} />
            
            <Route path="/tools/resume-generator/setinfo" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/setinfo/:coverLetterId" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/generate/:coverLetterId" element={<ProtectedRoute element={ResumeGeneratorPage2} />} />
            <Route path="/coverletter/view/:coverLetterId" element={<ProtectedRoute element={ResumeViewPage} />} />
            <Route path="/question/view/:questionId" element={<ProtectedRoute element={QuestionViewPage} />} />
            <Route path="/mypage/*" element={<ProtectedRoute element={MyPage} />} />
            <Route path="/tools/photo-generator" element={< PhotoGeneratorPage />} />
            <Route path="/tools/questions-setInfo" element={< QuestionSetInfoPage1 />} />
            <Route path="/tools/questions-setCategory" element={< ProtectedRoute element={QuestionSetInfoPage2} />} />
            <Route path="/tools/tech-tree-generator" element={< ProtectedRoute element={TechTreeGeneratorPage} />} />
            <Route path="/tools/tech-tree/:techTreeId" element={< ProtectedRoute element={TechTreeDetail} />} />
            <Route path="/tools/job-matching" element={< JobMatchingPage />} />
            <Route path="/tools/ai-interviewer" element={< AIInterviewerPage />} />
            <Route path="/tools/resume-evaluation" element={<ProtectedRoute element={ResumeEvaluationPage} />} /> 
            <Route path="/saved-evaluations" component={SavedResumeEvaluation} />
            <Route path="/resume-evaluation/:id" element={<ResumeEvaluationDetail />} />
            
            <Route path="/community" element={<ProtectedRoute element={CommunityPage} />} />
            <Route path="/community/post/:postId" element={<ProtectedRoute element={PostDetail} />} />
            <Route path="/community/writePost" element={<ProtectedRoute element={WritePost} />} />
            <Route path="/community/edit/:postId" element={<ProtectedRoute element={EditPost} />} /> {/* 새로 추가 */}
            <Route path="/tools/resume-builder" element={<ProtectedRoute element={ResumeBuilderPage} />} />
            <Route path="/tools/resume-preview" element={<ProtectedRoute element={ResumePreviewPage} />} />
            <Route path="/tools/resume-builder/preview" element={<ProtectedRoute element={ResumePreviewPage} />} />
            <Route path="/tools/resume-builder/:resumeId" element={<ProtectedRoute element={ResumeBuilderPage} />} />
            <Route path="/mypage/saved-builder-resumes" element={<ProtectedRoute element={SavedBuilderResumes} />} />
            <Route path="/tools/resume-builder/view/:resumeId" element={<ProtectedRoute element={ResumeBuilderView} />} />
          </Route>
          <Route element={<AdminLayout />}>
            <Route path="/admin" element={<AdminProtectedRoute element={AdminDashboard} />} />
            <Route path="/admin/users" element={<AdminProtectedRoute element={UsersManagementPage} />} />
            <Route path="/admin/community" element={<AdminProtectedRoute element={CommunityManagementPage} />} />
            <Route path="/admin/reports" element={<AdminProtectedRoute element={ReportsManagementPage} />} />
            
            {/* 통계 관련 라우트 */}
            <Route path="/admin/stats/visitors" element={<AdminProtectedRoute element={VisitorStatsPage} />} />
            <Route path="/admin/stats/members" element={<AdminProtectedRoute element={NewMemberStatsPage} />} />
            <Route path="/admin/stats/revenue" element={<AdminProtectedRoute element={RevenueStatsPage} />} />
            
            {/* 고객소통 관련 라우트 */}
            <Route path="/admin/communication/customer-email" element={<AdminProtectedRoute element={CustomerEmailPage} />} />
            <Route path="/admin/communication/notice" element={<AdminProtectedRoute element={NoticePage} />} />
            <Route path="/admin/communication/notification" element={<AdminProtectedRoute element={NotificationPage} />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
