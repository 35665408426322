import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom'; // react-router-dom import
import { getToken } from '../../utils/auth';
import './Payment.css'; // CSS 파일 import

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// 가격에 맞는 토큰 계산
const calculateTokens = (price, tokenRate) => {
  return Math.round(price * tokenRate);
};

const PlanCard = ({ title, basePrice, tokenRate, minPrice, maxPrice, features, isActive, onSubscribe, isFree }) => {
  const [price, setPrice] = useState(minPrice);
  const tokens = calculateTokens(price, tokenRate);

  const handlePriceChange = (e) => {
    const newPrice = Math.round(parseInt(e.target.value) / 5000) * 5000; // 5000원 단위로 가격 변경
    setPrice(newPrice);
  };

  return (
    <div className={`plan-card ${isActive ? 'active' : ''}`}>
      <h3>{title}</h3>
      <p className="price">{price.toLocaleString()}원 / 월</p>
      {isFree ? (
        // 'Free plan'은 슬라이드 없이 고정된 가격만 표시
        <div className="token-amount">{tokens.toLocaleString()} tokens</div>
      ) : (
        <div className="token-slider">
          <div className="token-amount">{tokens.toLocaleString()} tokens</div>
          <input 
            type="range" 
            min={minPrice} 
            max={maxPrice} 
            value={price} 
            onChange={handlePriceChange}
          />
        </div>
      )}
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={16} />
            {feature}
          </li>
        ))}
      </ul>
      <button className={isActive ? 'active' : ''} onClick={() => onSubscribe(title, price, tokens)}>
        {isActive ? '이용중' : '구독하기'}
      </button>
    </div>
  );
};

// 구독 방식 선택
const Payment = () => {
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState('Free');  // 기본값을 'Free'로 설정

  // 구독 정보 불러오기
  const getSubscription = async () => {
    const token = getToken();
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubscription(data.subscribePlan); // 구독정보 입력
      } else {
        console.error('구독 정보 불러오기 실패');
      }
    } catch (error) {
      console.error('Error fetching list counts:', error);
    }
  };

  useEffect(() => {
    getSubscription();  // 컴포넌트가 마운트될 때 구독 정보를 불러옴
  }, []);

  const plans = [
    {
      title: 'Free plan',
      basePrice: 0,
      tokenRate: 0,  // 무료 플랜은 토큰이 없으므로 0
      minPrice: 0,
      maxPrice: 0,
      features: ['베이직 툴', '5,000 tokens'],
      isActive: subscription === 'Free', // 구독 플랜이 'Free'라면 활성화
      isFree: true // 'Free plan'은 슬라이드 제거
    },
    {
      title: 'Starter plan',
      basePrice: 10000,
      tokenRate: 5,  // 1원당 5토큰
      minPrice: 5000,
      maxPrice: 30000,
      features: ['스타터 툴', '커스텀 지원'],
      isActive: subscription === 'starter plan', // 구독 플랜이 'starter plan'이라면 활성화
      isFree: false // 'Starter plan'은 슬라이드 있음
    },
    {
      title: 'Professional plan',
      basePrice: 50000,
      tokenRate: 6,  // 1원당 6토큰
      minPrice: 50000,
      maxPrice: 100000,
      features: ['프로 툴', '커스텀 지원', '전문 멘토 매칭'],
      isActive: subscription === 'pro plan', // 구독 플랜이 'pro plan'이라면 활성화
      isFree: false // 'Professional plan'은 슬라이드 있음
    }
  ];

  const handleSubscribe = (planTitle, price, tokens) => {
    const Token = getToken();
    if (!Token) {
      alert('로그인 후 이용해주세요.');
      navigate('/login');
    } else {
      // 결제 정보와 함께 /payment/method로 리다이렉트
      navigate('/payment/method', { state: { planTitle, price, tokens } });
    }
  };

  return (
    <div className='payment-page'>
      <h2>구독정보</h2>
      <div className="payment-container">
        <div className="plan-cards">
          {plans.map((plan, index) => (
            <PlanCard key={index} {...plan} onSubscribe={handleSubscribe} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Payment;