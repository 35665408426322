import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // useLocation 훅을 import
import { getToken } from '../../utils/auth';
import * as PortOne from "@portone/browser-sdk/v2";
import './PaymentPage.css'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function PaymentPage() {
  const location = useLocation();   // 이전 페이지에서 변수값을 받기 위함
  const { planTitle, price } = location.state || { planTitle: '', price: 0 };   // 구독플랜, 가격
  const storeId = process.env.REACT_APP_PORTONE_STORE_ID;  // PortOne storeId
  const TosschannelKey = process.env.REACT_APP_PORTONE_TOSS_CHANNEL_KEY;  // Toss Channel key
  const KakaochannelKey = process.env.REACT_APP_PORTONE_KAKAO_CHANNEL_KEY;  // Kakaopay Channel Key

  // 빌링 키 발급함수
  const issueBillingKey = async () => {
    try {
      // 사용자 인증 토큰 가져오기
      const token = getToken();
      if (!token) {
        return alert('로그인 상태가 아닙니다. 다시 로그인해 주세요.');
      }
  
      // 빌링 키 발급 요청
      const issueResponse = await PortOne.requestIssueBillingKey({
        channelKey: TosschannelKey, // 채널 키
        storeId: storeId, // 상점 ID
        billingKeyMethod: "CARD", // 토스페이먼츠 -> 카드로만 가능
      });

      console.log(issueResponse);
  
      // 빌링 키 발급 오류 처리
      if (issueResponse.code !== undefined) {
        console.error("빌링 키 발급 실패:", issueResponse.message);
        return alert(issueResponse.message);
      }
  
      // 발급된 빌링 키를 백엔드로 전송
      const response = await fetch(`${BACKEND_URL}/billings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token, // 인증 토큰 추가
        },
        body: JSON.stringify({
          billingKey: issueResponse.billingKey, // 발급된 빌링 키
          planTitle, // 구독 플랜 이름
        }),
      });
  
      // 백엔드 응답 처리
      if (!response.ok) {
        const errorData = await response.json();
        console.error("백엔드 저장 실패:", errorData.error || "알 수 없는 오류");
        return alert("백엔드에 빌링 키 저장 중 오류가 발생했습니다.");
      }
  
      const result = await response.json();
      alert(`빌링 키가 성공적으로 발급되었습니다: ${result.billingKey}`);
  
      return issueResponse.billingKey; // 발급된 빌링 키 반환
    } catch (error) {
      console.error("빌링 키 발급 중 오류:", error.message || error);
      alert("빌링 키 발급 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  // 간편 결제 요청 함수
  const requestEasyPayment = async (channelKey, easyPayProvider) => {
    try {
      // 1. 사용자 인증 토큰 가져오기
      const token = getToken();
  
      if (!token) {
        return alert('로그인 상태가 아닙니다. 다시 로그인해 주세요.');
      }
  
      // 2. 백엔드에 결제 정보 생성 요청
      const createPaymentResponse = await fetch(`${BACKEND_URL}/payment/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token, // 인증 토큰 추가
        },
        body: JSON.stringify({
          amount: price,     // 결제 금액
          plan: planTitle,   // 구독 플랜 이름
        }),
      });
  
      if (!createPaymentResponse.ok) {
        const errorData = await createPaymentResponse.json();
        console.error("결제 정보 생성 실패:", errorData.error || "알 수 없는 오류");
        return alert("결제 정보 생성 중 문제가 발생했습니다. 다시 시도해주세요.");
      }
  
      const createPaymentResult = await createPaymentResponse.json();
      const paymentId = createPaymentResult.paymentId; // 생성된 paymentId 가져오기
  
      // 3. 결제 요청 실행
      const response = await PortOne.requestPayment({
        storeId: storeId,
        paymentId: paymentId, // 생성된 paymentId 사용
        orderName: planTitle, // 주문 이름
        totalAmount: price,   // 결제 금액
        currency: "KRW",      // 통화
        channelKey: channelKey,     // 채널 키
        payMethod: "EASY_PAY",
        easyPay: {
          easyPayProvider: easyPayProvider,
        },
        //noticeUrls: [`${BACKEND_URL}/payment/webhook`],  // 웹훅 URL 설정
      });
  
      // 결제 오류 처리
      if (response.code !== undefined) {
        return alert(response.message);
      }
  
      // 4. 백엔드에 결제 확인 요청
      const notifiedResponse = await fetch(`${BACKEND_URL}/payment/complete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token, // 인증 토큰 추가
        },
        body: JSON.stringify({
          paymentId: paymentId,     // 결제 ID
          orderName: planTitle,     // 구독 플랜
        }),
      });
  
      const result = await notifiedResponse.json();
  
      // 백엔드 응답 처리
      if (notifiedResponse.ok) {
        alert(`결제가 완료되었습니다! 지급된 토큰: ${result.tokensAdded}`);
      } else {
        alert(`결제 처리 중 문제가 발생했습니다: ${result.error}`);
        console.log(result.error);
      }
    } catch (error) {
      console.error("결제 실패:", error.message || error);
      alert("결제 요청 중 오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };


  return (
  <div className="paymentpage-container">
    <h1 className="payment-title">토큰 추가 구매</h1>
    <div className="payment-box">
      <p className="payment-info">구독 플랜: <span className="highlight">{planTitle}</span></p>
      <p className="payment-info">결제 금액: <span className="highlight">{price}</span></p>

      <div className="payment-method-container">
        <p className="payment-method-label">결제 방식</p>
        <div className="payment-buttons">
          <button
            className="payment-button kakao-pay-button"
            onClick={() => requestEasyPayment(KakaochannelKey, "KAKAOPAY")}
          >
            카카오페이
          </button>
          <button
            className="payment-button naver-pay-button"
            onClick={() => requestEasyPayment(TosschannelKey, "NAVERPAY")}
          >
            네이버페이
          </button>
          <button
            className="payment-button toss-pay-button"
            onClick={() => requestEasyPayment(TosschannelKey, "TOSSPAY")}
          >
            토스페이
          </button>
      </div>
    </div>
      <div className="billing-key-container">
        <button className="billing-key-button" onClick={issueBillingKey}>
          빌링 키 발급
        </button>
      </div>
    </div>
  </div>
  );
}

export default PaymentPage;