import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import axios from 'axios';
import './GetBillingKey.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PORTONE_API_SECRET = process.env.REACT_APP_PORTONE_API_SECRET;
const storeId = process.env.REACT_APP_PORTONE_STORE_ID;
const TosschannelKey = process.env.REACT_APP_PORTONE_TOSS_CHANNEL_KEY;

function GetBillingKey() {
  const location = useLocation();
  const { planTitle, price } = location.state || { planTitle: '', price: 0 };

  const [cardNumber, setCardNumber] = useState(['', '', '', '']);
  const [expiryDate, setExpiryDate] = useState('');
  const [birthNumber, setBirthNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // 카드 번호 입력 핸들러 (숫자만 허용)
  const handleCardNumberChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 4) {
      const newCardNumber = [...cardNumber];
      newCardNumber[index] = value;
      setCardNumber(newCardNumber);
    }
  };

  // 유효기간 MM/YY 형식 입력 핸들러
  const handleExpiryDateChange = (value) => {
    // 숫자만 허용, 최대 5자리(MM/YY)
    if (/^\d{0,4}$/.test(value.replace('/', ''))) {
      if (value.length === 2 && !value.includes('/')) {
        value = value + '/'; // 자동으로 슬래시 추가
      }
      setExpiryDate(value);
    }
  };

  // 숫자 입력만 허용하는 핸들러
  const handleNumericInput = (setter) => (value) => {
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const issueBillingKey = async () => {
    try {
      setLoading(true);

      const token = getToken();
      if (!token) {
        alert('로그인 상태가 아닙니다. 다시 로그인해 주세요.');
        setLoading(false);
        return;
      }

      // 카드 번호 합치기
      const completeCardNumber = cardNumber.join('');
      // 유효기간 분리 (MM과 YY)
      const [expiryMonth, expiryYear] = expiryDate.split('/');

      // PortOne API 호출
      const response = await axios({
        url: 'https://api.portone.io/billing-keys',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PortOne ${PORTONE_API_SECRET}`,
        },
        data: {
          storeId: storeId,
          channelKey: TosschannelKey,
          method: {
            card: {
              credential: {
                number: completeCardNumber,
                expiryYear: expiryYear,
                expiryMonth: expiryMonth,
                birthOrBusinessRegistrationNumber: birthNumber,
                passwordTwoDigits: password,
              },
            },
          },
        },
      });

      const { billingKey } = response.data;

      // 빌링 키 백엔드 저장
      const backendResponse = await fetch(`${BACKEND_URL}/billings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          billingKey,
          planTitle,
        }),
      });

      if (!backendResponse.ok) {
        const errorData = await backendResponse.json();
        console.error('백엔드 저장 실패:', errorData.error || '알 수 없는 오류');
        alert('백엔드에 빌링 키 저장 중 오류가 발생했습니다.');
        setLoading(false);
        return;
      }

      const result = await backendResponse.json();
      alert(`빌링 키가 성공적으로 발급되었습니다: ${result.billingKey}`);
      setLoading(false);
    } catch (error) {
      console.error('빌링 키 발급 중 오류:', error.message || error);
      alert('빌링 키 발급 중 오류가 발생했습니다. 다시 시도해 주세요.');
      setLoading(false);
    }
  };

  return (
    <div className="getbilling-page">
      <h2>결제 페이지</h2>
      <p>구독 플랜: {planTitle}</p>
      <p>가격: {price}원</p>

      <div className="card-info">
        <label>
          카드 번호
          <div className="card-number-inputs">
            {cardNumber.map((num, index) => (
              <input
                key={index}
                type="text"
                value={num}
                onChange={(e) => handleCardNumberChange(index, e.target.value)}
                placeholder="0000"
                maxLength="4"
                className="card-number-input"
              />
            ))}
          </div>
        </label>
        <label>
          유효 기간 (MM/YY)
          <input
            type="text"
            value={expiryDate}
            onChange={(e) => handleExpiryDateChange(e.target.value)}
            placeholder="MM/YY"
            maxLength="5"
          />
        </label>
        <label>
          생년월일 또는 사업자 등록 번호
          <input
            type="text"
            value={birthNumber}
            onChange={(e) => handleNumericInput(setBirthNumber)(e.target.value)}
            placeholder="생년월일 또는 사업자 등록 번호"
            maxLength="13"
          />
        </label>
        <label>
          카드 비밀번호 (2자리)
          <input
            type="password"
            value={password}
            onChange={(e) => handleNumericInput(setPassword)(e.target.value)}
            placeholder="카드 비밀번호 (2자리)"
            maxLength="2"
          />
        </label>
      </div>

      <button
        onClick={issueBillingKey}
        disabled={loading}
        className="issue-billing-button"
      >
        {loading ? '처리 중...' : '빌링 키 발급'}
      </button>
    </div>
  );
}

export default GetBillingKey;